import AlertService from '@/common/alert.service'
import ToastificationSuccess from '@core/components/toastification/ToastificationSuccess.vue'
import { computed, onMounted } from '@vue/composition-api'
import store from '@/store'

export default function useReferans() {
    const tableColumns = [
        { key: 'sLogoUrl', label: 'Logo' },
        { key: 'sAciklama', label: 'Açıklama' },
        { key: 'isActive', label: 'Aktif Durum', tdStyle: 'width:5px !important', thStyle: 'width:5px !important' },
        { key: 'actions', label: 'Aksiyon', class: 'text-right' },
    ]

    function fetchReferanses() {
        store.dispatch('referansYonetim/fetchReferanses', {})
    }

    onMounted(fetchReferanses)

    const getReferanses = computed(() => store.getters['referansYonetim/getReferanses'])

    function addNew() {
        this.$router.push({ name: 'manage-website-referans-save', params: { id: -1 } })
    }

    function remove(id) {
        AlertService.confirmDelete(this, () => {
            store.dispatch('referansYonetim/removeReferans', id)
                .then(response => {
                    if (response.statusCode === 200) {
                        this.$toast({
                            component: ToastificationSuccess,
                            position: 'top-right',
                            props: { text: response.message },
                        })

                        this.fetchReferanses();
                    } else {
                        AlertService.error(this, response.message)
                    }
                });
        })
    }

    function deactivate(id) {
        store.dispatch('referansYonetim/toggleActiveReferans', id)
            .then(response => {
                if (response.statusCode === 200) {
                    this.$toast({
                        component: ToastificationSuccess,
                        position: 'top-right',
                        props: { text: response.message },
                    });
                } else {
                    AlertService.error(this, response.message);
                }
            })
    }

    return {
        fetchReferanses,
        getReferanses,
        tableColumns,

        addNew,
        remove,
        deactivate,
    }
}
