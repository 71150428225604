<template>
  <div>
    <crud-button
      :onClickList="fetchReferanses"
      :onClickAddNew="addNew"
    />
    <b-card no-body class="mb-0">
      <b-table
        class="position-relative"
        :items="getReferanses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Kayıt bulunamadı"
      >
        <!-- Column: User -->
        <template #cell(sLogoUrl)="data">
          <b-img :src="data.item.sLogoUrl" height="60" width="130" alt="logo" />
        </template>
        <template #cell(isActive)="data">
          <b-form-checkbox
            :checked="data.item.isActive"
            class="custom-control-success"
            name="check-button"
            @change="deactivate(data.item.id)"
          >
          {{ $t('Aktif/Pasif') }}
          </b-form-checkbox>
        </template>
        <template #cell(actions)="data">
          <!-- Update -->
          <b-button
            size="sm"
            @click="
              $router.push({
                name: 'manage-website-referans-save',
                params: { id: data.item.id },
              })
            "
            variant="primary"
          >
            <span class="align-middle">{{ $t('Güncelle') }}</span>
          </b-button>

          <!-- Remove -->
          <b-button
            size="sm"
            class="ml-1 remove-button-mt-1"
            @click="remove(data.item.id)"
            variant="warning"
          >
            <span class="align-middle">{{ $t('Kaldır') }}</span>
          </b-button>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import CrudButton from '@/components/CrudButton.vue'
import useReferans from './useReferans';

export default {
  components: {
    CrudButton,
  },

  setup() {
    const {
      fetchReferanses,
        getReferanses,
        tableColumns,
        
        addNew,
        remove,
        deactivate,
    } = useReferans();

    return {
       fetchReferanses,
        getReferanses,
        tableColumns,
        
        addNew,
        remove,
        deactivate,
    }
  },
}
</script>

<style scoped>
@media (max-width: 890px) {
  .remove-button-mt-1 {
      margin-top: 10px !important;
  }
}
</style>
